import {Link} from "gatsby";
import * as React from "react";

export const ToAkamaruIntroductionPage = () => {
  return (
    <Link to={"/products/akamaru"}>
      <p className={"text-center"}>
        あかまるアプリの紹介ページに戻る
      </p>
    </Link>
  )
}
