import * as React from 'react';
import { graphql, Link } from 'gatsby';
import Layout from 'src/components/layout';
import Seo from "src/components/seo"
import { GatsbyImage } from "gatsby-plugin-image"
import {ToAkamaruIntroductionPage} from 'src/components/common/to_akamaru_introduction_page.js';

const AkamaruGameIndex = ({ data }) => {
  const gameNodes = data.allMarkdownRemark.nodes;
  const title = "「タッチであそぼ！あかまるどれかな？」 収録ゲームの紹介";
  return (
    <Layout>
    <Seo
      title={title}
      description="「タッチであそぼ！あかまるどれかな？」 に収録されているゲームを紹介しています。"
    />

      <section className="container article-list-container">
        <h1 itemprop="headline">「タッチであそぼ！あかまるどれかな？」<br/> 収録ゲームのご紹介</h1>
  
        <p>
          「いろ」・「かたち」・「かず」など、様々なジャンルのゲームを多数収録しています。ゲームを通して、楽しくお子様の認識力や論理的思考を育むことができます。
        </p>

        <div className="gameList">
          <ul>
            {gameNodes.map((node) => {
              const game = node.frontmatter;
              const thumbnail = game.thumbnail?.childImageSharp.gatsbyImageData;
              const link = `/products/akamaru${node.fields.slug}`

              return(
                <>
                  <li>
                    <Link to={link}>
                      <GatsbyImage
                        className="gameList__card"
                        image={thumbnail}
                        alt="たくさんはどっち"
                      />
                    </Link>
                  </li>
                </>
              );
            })}
          </ul>

        </div>

        <ToAkamaruIntroductionPage/>


      </section>
    </Layout>
  )
}

export const query = graphql`
  query {
    allMarkdownRemark(
      sort: {fields: [frontmatter___date], order: DESC}
      limit: 15
      filter: {fields: {sourceInstanceName: {eq: "akamaruGame"}}}
    ) {
      nodes {
        fields {
          slug
        }
        frontmatter {
          date
          title
          tag
          thumbnail {
            childImageSharp {
              gatsbyImageData(width: 920)
            }
          }
        }
      }
    }
  }
`;

export default AkamaruGameIndex;
